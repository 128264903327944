











































































































































































































































































































































.menubox .el-badge__content {
    top: 300px !important;
    right: 50px !important;
    height: 16px;
    line-height: 16.5px;
}
